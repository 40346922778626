import React from 'react';
import styled from 'styled-components';

const Wrapper  = styled.div`
`;
const ItemWrapper  = styled.div`
  display: inline-block;
  width: 42px;
  height: 42px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 42px;
  margin-right: 12px;
`;
const Item  = styled.a`
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: ${props => props.color ? props.color : "#ffffff"};
  mask: url("/icons/social-${props => props.social}.svg") no-repeat 100% 100%;
  mask-size: 100% 100%;
  border-radius: 40px;

  &:hover{
    background-color: ${props => props.color ? props.color : "#ffffff"}BB;
  }
`;

const Socials = ({ inverse }) => {
  return (
    <Wrapper>
      <ItemWrapper><Item color="#FF0084" href="https://instagram.com/lalunanuovaasd?igshid=dn76ifb19e5v" target="_blank" social="instagram" inverse={inverse} /></ItemWrapper>
      <ItemWrapper><Item color="#1877F2" href="https://www.facebook.com/CentroEquestreLaLunaNuovaASD/" target="_blank" social="facebook" inverse={inverse} /></ItemWrapper>
      <ItemWrapper><Item color="#131418" href="https://www.tiktok.com/@la.lunanuova?_t=ZS-8vFjlfpk50D&_r=1" target="_blank" social="tiktok" inverse={inverse} /></ItemWrapper>
      <ItemWrapper><Item color="#25D366" href="https://wa.me/393401737345" target="_blank" social="whatsapp" inverse={inverse} /></ItemWrapper>
      <ItemWrapper><Item color="#F57D00" href="mailto:info@lalunanuovamn.it" target="_blank" social="email" inverse={inverse} /></ItemWrapper>
    </Wrapper>
  )
}



export default Socials
