import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from './../../services/AnalyticsService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
import Cta from './../../common/components/Cta';
import Image from './../../common/components/Image';
// import Button from './../../common/components/Button';


const HomePage = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
      />
      <Header 
        text={"La Luna Nuova"}
        lowertext={"Centro Equestre"}
        page="homepage"
        alt="Emotional image"
      />
      
      <Section 
        variant="white" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Chi Siamo</Typo>
          <Typo type="p" align="center">
            La Luna Nuova SSD nasce nel 2024 da un progetto iniziato nel 2013 e realizzatosi nel tempo sino alla costituzione della stessa con i soci Marco Mantovani, Jessica Signorelli, Paola Minoia e Beatrice Lazzarini.
          </Typo>   
          <Typo type="p" align="center">
            Trattasi di una solida struttura in costante crescita ed espansione che interessa con le sue quotidiane attività sia adulti che bambini.  
          </Typo>   
          <Typo type="p" align="center">
            L’obbiettivo della squadra è quello di trasmettere tutta la conoscenza e l’esperienza acquisita nel mondo equestre a chiunque ne fosse interessato attraverso il coinvolgimento in svariate attività e nel rispetto dei nostri amici cavalli. Ma, soprattutto, l’obbiettivo comune è quello di crescere insieme divertendosi.
          </Typo>   
          <Typo type="p" align="center">
            Cerchiamo di offrire alla nostra clientela professionalità e cordialità nei nostri servizi su misura, in base alle esigenze del singolo soggetto, e ci teniamo anche ad essere un punto di riferimento per tutte le famiglie.  La sede principale della struttura si trova a pochi chilometri dal centro di Mantova nella splendida cornice del Parco del Mincio, mentre l'altra sede è ubicata nel comune di Borgoforte (MN).
          </Typo>      
          <Typo type="p" align="center">
            Ad aspettarti i nostri tecnici ELEONORA, DARIA, ELISABETTA, BEATRICE, PAOLA, JESSICA, MATTIA, MARCO.
          </Typo>   
        </Padding>
      </Section>
      
      <Section 
        variant="blue" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Cosa offriamo</Typo>
          <br/>
          <center>
            <Cta 
              size="big"
              variant="light-o"
              text="Scopri di piu"
              link="/cosa-offriamo"
              icon="arrow_forward"
            />
          </center>        
        </Padding>
      </Section>
      
      <Section 
        variant="white" 
        imageDx={"/web/sections/cosaoffriamo-lascuola.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">La scuola</Typo>
          <Typo type="p">
            L'attività principale del nostro centro è costituita dalla scuola di equitazione, che dispone di numerosi cavalli e anche di pony. Pratichiamo sia la monta inglese che la monta western. Durante tutto l'anno sono attivi corsi di avviamento per principianti e di...
          </Typo>    
          <Cta 
            size="big"
            variant="default-o"
            text="Scopri di più"
            link="/cosa-offriamo/scuola-equitazione-mantova"
            icon="arrow_forward"
          />
        </Padding>
      </Section>
      
      <Section 
        variant="blue" 
        imageSx={"/web/sections/cosaoffriamo-agonismo.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Agonismo</Typo>
          <Typo type="p">
            Monta da Lavoro - Gimkana: &egrave; una prova di abilità e velocità a cavallo, in cui il binomio deve eseguire nel minor tempo possibile un percorso obbligato dove sono inseriti una serie di ostacoli, che riproducono situazioni di difficoltà che ogni cavaliere potrebbe...
          </Typo>     
          <Cta 
            size="big"
            variant="light-o"
            text="Scopri di più"
            link="/cosa-offriamo/agonismo"
            icon="arrow_forward"
          />       
        </Padding>
      </Section>
      
      <Section 
        variant="white" 
        imageDx={"/web/sections/cosaoffriamo-turismoequestre.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Turismo Equestre</Typo>
          <Typo type="p">
            Non c'è niente di più bello che fare una passeggiata a cavallo in mezzo alla natura, circondati da vastissimi campi verdi e canali percorribili liberamente a cavallo. Sono previste passeggiate organizzate, su accompagnamento di personale autorizzato e...
          </Typo>    
          <Cta 
            size="big"
            variant="default-o"
            text="Scopri di più"
            link="/cosa-offriamo/passeggiate-a-cavallo-mantova"
            icon="arrow_forward"
          />         
        </Padding>
      </Section>
      
      <Section 
        variant="blue" 
        imageSx={"/web/sections/cosaoffriamo-attivitadidattiche.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Attività Didattiche</Typo>
          <Typo type="p">
            Settimane Verdi: durante il periodo estivo si organizzano le settimane verdi. Dal lunedì al venerdì, grazie alla partecipazione di personale preparato, i bambini imparano a stare a contatto con i cavalli e con la natura, intraprendendo anche svariate attività..
          </Typo>       
          <Cta 
            size="big"
            variant="light-o"
            text="Scopri di più"
            link="/cosa-offriamo/attivita-didattiche"
            icon="arrow_forward"
          />          
        </Padding>
      </Section>
      
      <Section 
        variant="white" 
        imageDx={"/web/sections/cosaoffriamo-pensionecavalli.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Pensione Cavalli</Typo>
          <Typo type="p">
            Scuderia: il centro offre la possibilità di scuderizzazione in box, provvisto di un paddock esterno privato. La lettiera è disponibile sia in truciolo che in paglia. Paddock Esterno: il centro offre la possibilità di collocare i cavalli in ampi paddock esterni. Tutti...
          </Typo>    
          <Cta 
            size="big"
            variant="default-o"
            text="Scopri di più"
            link="/cosa-offriamo/pensione-cavalli-mantova"
            icon="arrow_forward"
          />             
        </Padding>
      </Section>

      
      <Section 
        variant="white" 
      >
        <Padding top right bottom left>
          <Row>
            <Col widthmd={12}>
              <Typo type="h2" align="center">Partner</Typo>
            </Col>
            <Col widthmd={6}>
              <center>
                <a href="https://www.fitetrec-ante.it/" target="_blank" rel="noreferrer">
                  <Image src="fitetrec-logo.png" alt="Logo Fitetrec" width="180px" />
                </a>
              </center>
            </Col>
            <Col widthmd={6}>
              <center>
                <a href="https://www.sef-italia.it/" target="_blank" rel="noreferrer">
                  <Image src="sef-italia-logo.jpg" alt="Logo Fitetrec" width="200px" />            
                </a>
              </center>
            </Col>
          </Row>
        </Padding>
      </Section>
    </Layout>
  )
}

export default HomePage
