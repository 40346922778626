import React, {useEffect} from 'react';
import styled from 'styled-components';

import AnalyticsService from './../../services/AnalyticsService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';

import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
// import Icon from "./../../common/components/Icon";


const Map = styled.div`
  margin-bottom: -6px;
`;

const DoveSiamo = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  let iframe1 = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53562.1324724449!2d10.845239032844637!3d45.12607589569508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f7faaec87b0d1%3A0x6568b11291ee813!2sLA%20LUNA%20NUOVA%20Mantova%20Centro%20Equestre!5e0!3m2!1sit!2sit!4v1741605710075!5m2!1sit!2sit" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';
  let iframe2 = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.782002647168!2d10.761631612322335!3d45.04964547094955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47802ba5d0a62f93%3A0x4fd72ccf48587b82!2sLA%20LUNA%20NUOVA%20Borgoforte%20Centro%20Equestre!5e0!3m2!1sit!2sit!4v1743760294761!5m2!1sit!2sit" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';

  
  return (
    <Layout layout={layout}>
      <Meta
        title={"Dove Siamo"}
      />
      <Header 
        text={"La Luna Nuova"}
        lowertext={"Centro Equestre"}
        page="dovesiamo"
        alt="Emotional image"
      />
      {/* <Map dangerouslySetInnerHTML={{__html: iframe}} /> */}


      <Section 
        variant="white" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Dove Siamo</Typo>
          <Typo type="p">
          </Typo> 
        </Padding>
      </Section>

      <Section 
        variant="blue" 
        minHeight="500px"
      >
        <Row>
          <Col widthmd={6}>
            <Padding top right bottom left>
              <Typo type="h2" align="center">LA LUNA NUOVA<br/>Mantova</Typo>
              <Typo type="p">
                Ci troviamo in Strada Luigi Cadorna 9 nel comune di Mantova (MN).<br/>
                Il centro è aperto dal Martedì alla Domenica dalle ore 08.30 alle ore 12:30 e dalle ore 15:00 alle ore 20:00.<br/>
                Il centro rimane chiuso il Lunedì.<br/>
                Riceviamo su prenotazione.
              </Typo> 
            </Padding>
          </Col>
          <Col widthmd={6}>
            <Map dangerouslySetInnerHTML={{__html: iframe1}} />
          </Col>
        </Row>
      </Section>

      <Section 
        variant="white" 
        minHeight="500px"
      >
        <Row>
          <Col widthmd={6}>
            <Map dangerouslySetInnerHTML={{__html: iframe2}} />
          </Col>
          <Col widthmd={6}>
            <Padding top right bottom left>
              <Typo type="h2" align="center">LA LUNA NUOVA<br/>Borgoforte</Typo>
              <Typo type="p">
                Ci troviamo in Via Argine Boccadiganda 37,  nel comune di Borgoforte (MN).<br/>
                Il centro è aperto dal Martedì al Sabato dalle ore 09:00 alle ore 19:00 e alla Domenica dalle ore 09:00 alle ore 12:30.<br/>
                Il centro rimane chiuso il Lunedì.<br/>
                Riceviamo su prenotazione al numero 340 1737345.<br/>
              </Typo> 
            </Padding>
          </Col>
        </Row>
      </Section>
    </Layout>
  )
}

export default DoveSiamo;
