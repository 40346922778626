import React, {useState, useEffect} from 'react';

import StorageService from '../../services/StorageService';

const Image = ({ 
  src, 
  width, 
  height,
  title,
  alt
}) => {
  const [srcImage, setSrcImage] = useState(null);
  
  useEffect(() => {
    if(src){
      StorageService.getUrl("/web/assets/"+src).then((response) => {
        setSrcImage(response);
      });
    }
  }, [src]);

  return (
    <img 
      src={srcImage} 
      width={width} 
      height={height} 
      title={title} 
      alt={alt} 
    />
  )
}

export default Image
